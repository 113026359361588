import 'react-app-polyfill/ie11'
// import 'core-js/fn/array/fill'
// import 'core-js/fn/array/find'
// import 'core-js/fn/object/keys'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { MuiThemeProvider as Theme } from '@material-ui/core/styles'
import { GlobalProvider } from 'context'
import { theme } from 'helpers'
import store from './store'
import * as serviceWorker from './serviceWorker'
import LoadingContainer from './components/LoadingContainer'
import App from './containers'
import './index.css'

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Theme theme={theme}>
        <GlobalProvider>
          <React.Suspense fallback={<LoadingContainer />}>
            <App />
          </React.Suspense>
        </GlobalProvider>
      </Theme>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)

serviceWorker.register()
