import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from 'actions/portal'
import Typography from 'components/Typography'
import Loading from 'components/Loading'
import View from 'components/View'
import { request } from 'helpers'
import Fade from '@material-ui/core/Fade'

class Link extends Component {
  state = { error: false }

  componentDidMount() {
    const { UID } = this.props.match.params
    if (UID !== 'redirect') {
      this.signInLink(UID)
    }
  }

  async signInLink(UID) {
    try {
      this.props.history.push('/link/redirect')
      const response = await this.props.signInLink(UID)
      if (response.data.Type === 'LinkTicket') {
        this.props.history.replace(`/afsnit/${UID}`)
      } else {
        const scopes = response.data.AccessScopes || {}
        if (scopes.length > 0) {
          const paragraphId = response.data.ParagraphId
          const data = await request.get(
            `api/Paragraphs/${paragraphId}?omitStats=true`
          )
          const paragraph = data.data
          const course = scopes.find(scope => scope.Key === 'CourseId')
          const redirectURL = `/kurser/${
            course ? course.Value : paragraph.Course.FriendlyName
          }/${paragraph.FriendlyName}/noAutoplay`
          this.props.history.replace(redirectURL)
        } else {
          this.props.history.replace('/')
        }
      }
    } catch (error) {
      this.setState({ error: true })
    }
  }

  render() {
    return (
      <View
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {this.state.error ? (
          <Typography variant="display2" style={{ padding: 100 }}>
            Linket du har klikket på er desværre nedlagt – kontakt din
            IT-afdeling for et korrekt link
          </Typography>
        ) : (
          <Fade in style={{ transitionDelay: '800ms' }}>
            <Loading color="primary" size={96} />
          </Fade>
        )}
      </View>
    )
  }
}

const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps, actions)(Link)
