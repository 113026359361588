import axios from 'axios'
import { request } from 'helpers'

const signIn = (username, password) => dispatch =>
  axios({
    method: 'post',
    url: request.apiDomain() + 'token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: `username=${username}&password=${password}&grant_type=password`
  }).then(response =>
    dispatch({
      type: 'SIGN_IN',
      payload: response
    })
  )

const signInLink = LinkId => async dispatch => {
  try {
    const response = await request.get(`api/Links(${LinkId})`)
    dispatch({ type: 'SET_TOKEN', payload: response.data, scoped: true })
    return response
  } catch (error) {
    throw Error('Could not load link')
  }
}

const getUserInfo = () => dispatch =>
  request.get('api/Account/UserInfo').then(response => {
    dispatch({
      type: 'GET_USER_INFO',
      payload: response
    })
  })

const refreshToken = () => dispatch =>
  request
    .post('api/Account/RefreshToken')
    .then(response =>
      dispatch({
        type: 'REFRESH_TOKEN',
        payload: response
      })
    )
    .catch(error => {
      dispatch({
        type: 'SIGN_OUT'
      })
    })

const signOut = () => dispatch =>
  dispatch({
    type: 'SIGN_OUT'
  })

const showMenu = () => dispatch =>
  dispatch({
    type: 'SHOW_MENU'
  })

const hideMenu = () => dispatch =>
  dispatch({
    type: 'HIDE_MENU'
  })

export {
  signIn,
  signInLink,
  signOut,
  getUserInfo,
  refreshToken,
  showMenu,
  hideMenu
}
