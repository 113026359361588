import axios from 'axios'
import store from 'store'

let cancelRequestFetch = []

export default class request {
  static token = () => {
    return store.getState().user.token
  }

  static appDomain = () => {
    if (window.location.hostname.indexOf('192.168.1.173') > -1) {
      return 'http://192.168.1.173:3000/'
    } else if (window.location.hostname.indexOf('localhost') > -1) {
      return 'http://localhost:3000/'
    } else if (window.location.hostname.indexOf('test') > -1) {
      return 'https://test.howtodo.dk/'
    } else {
      return 'https://howtodo.dk/'
    }
  }

  static apiDomain = () => {
    if (window.location.hostname.indexOf('192.168.1.173') > -1) {
      return 'https://testapi.howtodo.dk/'
    } else if (window.location.hostname.indexOf('localhost') > -1) {
      // return 'https://testlektioner-api.azurewebsites.net/'
      return 'https://testapi.howtodo.dk/'
    } else if (window.location.hostname.indexOf('test') > -1) {
      // return 'https://testlektioner-api.azurewebsites.net/'
      return 'https://testapi.howtodo.dk/'
    } else {
      return 'https://api.howtodo.dk/'
    }
  }

  static headers = () => {
    return {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache,no-store',
      Authorization: `Bearer ${store.getState().user.token}`
    }
  }

  static get = (path, key) => {
    const identity = key || path

    if (cancelRequestFetch.filter(x => x.key === identity)[0]) {
      cancelRequestFetch.filter(x => x.key === identity)[0].cancel()
    }

    return axios({
      method: 'get',
      url: request.apiDomain() + path,
      headers: request.headers(),
      cancelToken: new axios.CancelToken(c => {
        if (cancelRequestFetch.filter(x => x.key === identity)[0]) {
          cancelRequestFetch.filter(x => x.key === identity)[0].cancel = c
        } else {
          cancelRequestFetch.push({ key: identity, cancel: c })
        }
      })
    })
  }

  static delete = path => {
    return axios({
      method: 'delete',
      url: request.apiDomain() + path,
      headers: request.headers()
    })
  }

  static post = (path, data) => {
    return axios({
      method: 'post',
      url: request.apiDomain() + path,
      headers: request.headers(),
      data
    })
  }

  static patch = (path, data) => {
    return axios({
      method: 'patch',
      url: request.apiDomain() + path,
      headers: request.headers(),
      data
    })
  }

  static put = (path, data) => {
    return axios({
      method: 'put',
      url: request.apiDomain() + path,
      headers: request.headers(),
      data
    })
  }
}
